import React from "react";

import HeadshotImage from "./headshot.jpg";
import styles from "./index.module.css";

const Headshot: React.FC<{}> = () => {
  return <img src={HeadshotImage} className={styles.headshot} alt="DC" />;
};

export default Headshot;
