import React from "react";

import Headshot from "../components/Headshot";

const AboutMe: React.FC = () => {
  return (
    <React.Fragment>
      <Headshot />
      <h1>Hello!</h1>
      <p>
        My name is Daniel Chao, and I'm a sophomore at Stanford University studying Management
        Science and Engineering and Computer Science. I'm currently an undergraduate section leader
        for <a href="https://engr108.stanford.edu/">ENGR 108, Introduction to Matrix Methods</a> and
        I work as a Senior Consultant with{" "}
        <a href="https://consulting.stanford.edu">Stanford Consulting</a>.
      </p>
      <p>
        In the past, I've also led sections in the <a href="http://cs198.stanford.edu">CS 198</a>{" "}
        program for Stanford's introductory computer science course,{" "}
        <a href="http://cs106a.stanford.edu">CS 106A</a>.
      </p>
      <p>
        Does this website look incomplete so far? I'm still working on filling in all the details.
        You can follow my progress at this project's{" "}
        <a href="https://github.com/dchao19/portfolio">github page</a>.
      </p>
    </React.Fragment>
  );
};

export default AboutMe;
